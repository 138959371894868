import React, { useCallback, useEffect, useState } from "react"

import Styles from "./BackToTop.module.css"

const BackToTop: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        const fn = (ev: MouseEvent) => {
            if (window.scrollY > 100) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        }

        window.addEventListener("scroll", fn, { passive: true })

        return () => {
            window.removeEventListener("scroll", fn)
        }
    }, [])

    const scrollToTop = useCallback(() => {
        window.scrollTo(0, 0)
    }, [])

    return <button className={`${Styles.button} ${visible ? Styles.buttonVisible : ""}`} onClick={scrollToTop}>&uarr;</button>
}

export default BackToTop
