import React, { useState, useCallback } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

import Styles from "./Navigation.module.css"

interface Items<> {
    id: string,
    name: string,
    link: string,
}
interface NavigationProps<> {
    items: Items[]
}

const Navigation: React.FC<NavigationProps> = ({
    items
}) => {
      const { logo } = useStaticQuery(graphql`
          query MenuQuery {
              logo: file(relativePath: { eq: "logo.png" }) {
                  childImageSharp {
                      fluid(maxWidth: 200, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
      `)
    const [showNav, setShowNav] = useState(false)

    const toggleNav = useCallback(() => {
        setShowNav(nav => !nav)
    }, [setShowNav]);

    return (
        <nav className={Styles.navbarwrapper}>
            <div className={Styles.navbar}>
                <div className={Styles.logowrapper}>
                    <Link to="/" title="">
                        <GatsbyImage
                            className={Styles.logo}
                            fluid={logo.childImageSharp.fluid}
                            alt="Logo von Lindsey and friends"
                            title="Lindsey and friends - Hundeträume"
                            loading="lazy"
                        />
                    </Link>
                </div>
                <button className={Styles.iconbars} onClick={toggleNav}>
                    <span>
                        <span className={Styles.iconbarwrapper}>
                            <span className={Styles.iconbartop}></span>
                            <span className={Styles.iconbar}></span>
                            <span className={Styles.iconbar}></span>
                        </span>
                        <span className={Styles.iconbartext}>Menü</span>
                    </span>
                </button>
                <div className={Styles.menu}>
                    {items.map(item => (
                        <li
                            key={`navitem-${item.id}`}
                            className={`${Styles.navitem} ${Styles.navhover}`}
                        >
                            <Link to={item.link}>{item.name}</Link>
                        </li>
                    ))}

                    <li className={Styles.navitem}>
                        <a href="https://www.facebook.com/ziegenbruchniehaus">
                            facebook
                        </a>
                    </li>
                    <li className={Styles.navitem}>
                        <a href="https://www.instagram.com/_lindsey_and_friends_/">
                            Instagram
                        </a>
                    </li>
                </div>
            </div>
            <div className={`${showNav ? "block" : "hidden"}`}>
                <ul className={Styles.burgermenu}>
                    {items.map(item => (
                        <a
                            key={`navitem-${item.id}`}
                            className={Styles.navlink}
                            href={item.link}
                        >
                            <li className={Styles.navitem}>{item.name}</li>
                        </a>
                    ))}
                    <li className={Styles.navitem}>
                        <a href="https://www.facebook.com/ziegenbruchniehaus">
                            facebook
                        </a>
                    </li>
                    <li className={Styles.navitem}>
                        <a href="https://www.instagram.com/_lindsey_and_friends_/">
                            Instagram
                        </a>
                    </li>
                    <li className={Styles.navitem}>
                        <button onClick={toggleNav}>&times; Schließen</button>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navigation
