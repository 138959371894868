/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { RouteComponentProps } from "@reach/router"

// import "typeface-signika-negative"
// import "typeface-open-sans"

import "../../styles/tailwind.css"
import "../../styles/global.css"

import Navigation from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
import Styles from "./Layout.module.css"
import BackToTop from "../BackToTop/BackToTop"

const Layout: React.FC<{
    location: RouteComponentProps["location"]
}> = ({ location, children }) => {
    return (
        <>
            <div className={Styles.container}>
                <Navigation
                    items={[
                        {
                            id: "ueber",
                            name: "Über uns",
                            link: "#ueber",
                        },
                        {
                            id: "news",
                            name: "Aktuelles",
                            link: "#aktuelles",
                        },
                        {
                            id: "dog",
                            name: "Hundebedarf",
                            link: "#hundebedarf",
                        },
                        {
                            id: "pictures",
                            name: "Fotowand",
                            link: "#fotowand",
                        },
                    ]}
                />
                <main className={Styles.mainContent}>{children}</main>
                <Footer/>
            </div>
            <BackToTop/>
        </>
    )
}

export default Layout
