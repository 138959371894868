import React from "react"

import Styles from "./Section.module.css"

interface SectionProps<> {
    className?: string
    sectionsmall?: boolean
}
const Section: React.FC<SectionProps> = ({
    className,
    sectionsmall,
    children
}) => {
    return (
        <section className={className}>
            <div
                className={`${sectionsmall ? Styles.sectionsmall : Styles.section}`}
            >
                {children}
            </div>
        </section>
    )

}

export default Section
